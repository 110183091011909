import React from 'react';
import { Login } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

//const entrypoint = 'http://ecopelapi.local/reset-password';
// const entrypoint = 'https://api-ecopel.app.strategia.io/reset-password';
const entrypoint = 'https://admin.ecopel.strategia-test.com/reset-password';

const useStyles = makeStyles({
    root: {
        backgroundColor:"#FFAC00",
        color:"#FFAC00",
        backgroundImage:"radial-gradient(circle at 50% 14em, #FFAC00 0%, #FFAC00 60%, #FFAC00 100%)"
    },
    image: {
        display: 'flex',
        height: 'auto',
        margin:'auto'
    },
    link: {
        display: 'block',
        textAlign: 'center',
        color: '#fff',
        marginTop: '1em',
        textDecoration: 'underline'
    }
});

const CustomLoginPage = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <img className={classes.image} src="logo_Ecopel.svg" alt="Description de l'image" />
            <Link target="_blank" to={entrypoint} className={classes.link}>Forgot your password?</Link>
            <Login className={classes.root} {...props} />
        </div>
    );
};

export default CustomLoginPage;