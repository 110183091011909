const authProvider = {

    // forgotPassword à gérer coté api 
    forgotPassword: ({ email }) => {
        //const request = new Request('http://ecopelapi.local/api/forgot-password', {
       // const request = new Request('https://api-ecopel.app.strategia.io/api/forgot-password', {
           const request = new Request('https://admin.ecopel.strategia-test.com/api/forgot-password', {
            method: 'POST',
            body: JSON.stringify({ email }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .catch(() => {
                throw new Error('Network error')
            });
    },

    login: ({ username, password }) => {
       // const request = new Request('http://ecopelapi.local/api/login_check', {
       // const request = new Request('https://api-ecopel.app.strategia.io/api/login_check', {
            const request = new Request('https://admin.ecopel.strategia-test.com/api/login_check', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                localStorage.setItem('token', JSON.stringify(auth.token));
            })
            .catch(() => {
                throw new Error('Network error')
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    checkError: error => {
        if(error) {
            const status = error.status;
            if (status === 401 || status === 403) {
                localStorage.removeItem('token');
                return Promise.reject();
            }
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        // Remove the '#' at the start of the path
        const path = window.location.hash.substring(1);
    
        console.log(path);
    
        if (path === '/reset-password-request' || path.startsWith('/reset-password')) {
            console.log('Reset password route');
            return Promise.resolve();
        }
    
        return localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    },
    
    getPermissions: () => Promise.resolve(),
};

export default authProvider;
