import * as React from "react";
import { Show, SimpleShowLayout, TextField, FunctionField } from 'react-admin';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

const ProductShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="reference" label="Reference" />
                <TextField source="type" label="Type" />
                <TextField source="referenceType" label="Reference Type" />
                <TextField source="composition" label="Composition" />
                <TextField source="width" label="Largeur" />
                <TextField source="grammage" label="Grammage" />
                <TextField source="garmentPrice" label="Garments from 500 pcs Price in $" />
                <TextField source="priceInDollars300to499M" label="300-499/M Price in $" />
                <TextField source="priceInDollars500to999M" label="500-999/M Price in $" />
                <TextField source="priceInDollars1000M" label="1000/M Price in $" />
                <TextField source="color" label="Couleur" />
                <TextField source="picturelinks" label="Visuel" />
                  {/* <FunctionField
                        render={record => (
                            <img 
                                src={`http://ecopelapi.local/upload/${record?.reference}.jpg`} 
                                alt="Produit" 
                                onError={(e)=>{e.target.onerror = null; e.target.src="imageNotFound.svg"}}
                                style={{ maxHeight: '200px' }}
                            />
                        )}
                    /> */}

                    {/* <FunctionField
                        render={record => (
                            <img 
                                src={`https://api-ecopel.app.strategia.io/upload/${record?.reference}.jpg`} 
                                alt="Produit" 
                                onError={(e)=>{e.target.onerror = null; e.target.src="imageNotFound.svg"}}
                                style={{ maxHeight: '200px' }}
                            />
                        )}
                    />  */}

                    <FunctionField
                        render={record => (
                            <img 
                                src={`https://admin.ecopel.strategia-test.com/upload/${record?.reference}.jpg`} 
                                alt="Produit" 
                                onError={(e)=>{e.target.onerror = null; e.target.src="imageNotFound.svg"}}
                                style={{ maxHeight: '200px' }}
                            />
                        )}
                    />

                <Link to="/products">
                    <Button color="primary">
                        Retour à la liste
                    </Button>
                </Link>
            </SimpleShowLayout>
        </Show>
    );
};

export default ProductShow;
